









































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { Admin } from '@/api'
import vSelect from 'vue-select'
import URLManager from '@/services/Managers/URLManager'
import { Toast } from '@/mixins'
import { SearchType } from '@/types/enums'
import { GUID_REGEX, DISCORD_ID_REGEX } from '@/services/Validation/regexes'

@Component({
  components: {
    BanModal: () => import('@/components/BanModal.vue'),
    UnbanModal: () => import('@/components/UnbanModal.vue'),
    AdminAccountNoteModal: () => import('@/components/AdminAccountNoteModal.vue'),
    Pagination: () => import('@/components/Pagination.vue'),
    'v-select': vSelect
  }
})
export default class UserList extends Mixins(Toast) {
  tableColumns = [
    { key: 'username', sortable: true },
    { key: 'email', sortable: true },
    { key: 'displayAuthority', label: 'Authority', sortable: true },
    { key: 'money', sortable: true },
    { key: 'twoFactorActivated' },
    { key: 'discordId', label: 'Discord Info' },
    { key: 'actions' }
  ]

  users = []
  authorities: string[] = []
  perPage = 10
  currentPage = 0
  totalUsers = 0
  totalPages = 0
  filters = {
    search: '',
    authority: ''
  }

  @Watch('filters.search', { deep: true })
  @Watch('filters.authority', { deep: true })
  async handleFilterAndUpdate(value) {
    this.currentPage = 1
    await this.refreshTable()

    URLManager.updateURLParams(this.authorities.includes(value) ? 'authority' : 'search', value)
  }

  async mounted() {
    this.authorities = await Admin.getAuthorities()

    const URLParams: { [key: string]: string } = URLManager.checkURLParams(['search', 'authority', 'pageIndex'])

    this.filters.search = URLParams.search
    this.filters.authority = URLParams.authority
    this.currentPage = parseInt(URLParams.pageIndex) || 1

    await this.refreshTable()
    this.$root.$on('refreshUserList', async () => await this.refreshTable())
  }

  async handlePageChange(value) {
    this.currentPage = value

    await this.refreshTable()
  }

  get searchType(): string {
    const isDiscordID = DISCORD_ID_REGEX.test(this.filters.search)
    const isGuid = GUID_REGEX.test(this.filters.search)

    if (isGuid) return SearchType.ACCOUNT_ID
    if (isDiscordID) return SearchType.DISCORD_ID

    return SearchType.PLAIN_TEXT
  }

  async refreshTable() {
    const { currentPage, data, totalCount, totalPages } = await Admin.getUsers({
      search: this.filters.search,
      authority: this.filters.authority === 'All' ? '' : this.filters.authority,
      pageIndex: this.currentPage.toString(),
      searchType: this.searchType
    })

    this.users = data
    this.totalUsers = totalCount
    this.totalPages = totalPages
    this.currentPage = currentPage

    URLManager.updateURLParams('pageIndex', this.currentPage)
  }

  formatDiscordUser(username, discriminator) {
    return `${username }#${discriminator}`
  }

  onFiltered(filteredItems) {
    if (this.totalUsers !== filteredItems.length) {
      this.totalUsers = filteredItems.length
      this.currentPage = 1
    }
  }

  async activateUser(userId: string) {
    await Admin.activateUser(userId)

    this.showSuccessToast({
      title: 'Account activated',
      message: `The account of the user ${userId} has been activated`
    })
  }


  viewUser(id: string) {
    this.$router.push(`/user/${id}`)
  }
}
